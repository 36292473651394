import Vue from "vue";
import VueRouter from "vue-router";
import Date from "../views/Date.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Date",
    component: Date
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history"
});

export default router;
