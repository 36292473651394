<template>
  <nav>
    <h1>Covid 19 Portugal - <span>Nuno</span></h1>
  </nav>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/mixins.scss";

nav {
  width: 100%;
  height: fit-content;
  padding: 1.6rem 3rem;
  background: #262626;
  box-shadow: 12px 12px 24px #202020, -12px -12px 24px #2c2c2c;

  h1 {
    font-family: "Montserrat";
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
    margin: 0;
    white-space: nowrap;
    font-size: 1.075rem;

    span {
      color: #16a58d;
      font-style: italic;
    }
  }
}
</style>
