import axios from "axios";
import API_URL from "./config";

let headers = {
  "Access-Control-Allow-Origin": "*"
};

const cityConfig = {
  getCitiesList: async () => {
    return await axios
      .get(`${API_URL}/get_county_list/`, headers)
      .then(response => {
        return {
          cities: response.data,
          status: response.status
        };
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },

  getCityInfo: async (yesterdayDate, todaysDate, city) => {
    return await axios
      .get(
        `${API_URL}/get_entry_county/${yesterdayDate}_until_${todaysDate}_${city}`,
        headers
      )
      .then(response => {
        return {
          info: response.data,
          status: response.status
        };
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },

  getInfoByDate: async date => {
    return await axios
      .get(`${API_URL}/get_entry/${date}`, headers)
      .then(response => {
        return {
          totalCasesByDate: response.data,
          status: response.status
        };
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  }
};

export default cityConfig;
