<template>
  <div class="date">
    <div class="calendar">
      <div class="calendar__stats">
        <div class="calendar__numbers" v-if="selectedDate != ''">
          <h1>
            Dados do dia <span>{{ selectedDate }}</span>
          </h1>
          <h3>Número de casos: {{ result.casos }}</h3>
        </div>
        <div class="calendar__numbers" v-else>
          <h1>Selecione um dia</h1>
          <h3>Número de casos: indeterminado</h3>
        </div>
      </div>
      <div class="calendars">
        <div v-if="currentTime()" class="calendar">
          <vc-date-picker
            v-model="selectedDate"
            :model-config="calendarConfig"
            :min-date="minDate"
            :max-date="new Date()"
            @dayclick="getData()"
            class="calendar__style"
            trim-weeks
          />
        </div>
        <div v-else>
          <vc-date-picker
            v-model="selectedDate"
            :model-config="calendarConfig"
            :min-date="minDate"
            :max-date="getYesterdayDate()"
            @dayclick="getData()"
            trim-weeks
          />
        </div>
      </div>
    </div>

    <div class="stats__grid" v-if="selectedDate != ''">
      <div class="casos">
        <div>
          <h3>Total de <span>casos</span> por idade até {{ selectedDate }}</h3>
          <p>
            Total de casos 0-9 anos: <span>{{ result.casosIdade.level1 }}</span>
          </p>
          <p>
            Total de casos 10-19 anos:
            <span>{{ result.casosIdade.level2 }}</span>
          </p>
          <p>
            Total de casos 20-29 anos:
            <span>{{ result.casosIdade.level3 }}</span>
          </p>
          <p>
            Total de casos 30-39 anos:
            <span>{{ result.casosIdade.level4 }}</span>
          </p>
          <p>
            Total de casos 40-49 anos:
            <span>{{ result.casosIdade.level5 }}</span>
          </p>
          <p>
            Total de casos 50-59 anos:
            <span>{{ result.casosIdade.level6 }}</span>
          </p>
          <p>
            Total de casos 60-69 anos:
            <span>{{ result.casosIdade.level7 }}</span>
          </p>
          <p>
            Total de casos 70-79 anos:
            <span>{{ result.casosIdade.level8 }}</span>
          </p>
          <p>
            Total de casos 80+ anos: <span>{{ result.casosIdade.level9 }}</span>
          </p>
        </div>
      </div>
      <div class="deaths">
        <h3>Total de <span>mortes</span> por idade até {{ selectedDate }}</h3>
        <p>
          Total de casos 0-9 anos: <span>{{ result.obitosIdade.level1 }}</span>
        </p>
        <p>
          Total de casos 10-19 anos:
          <span>{{ result.obitosIdade.level2 }}</span>
        </p>
        <p>
          Total de casos 20-29 anos:
          <span>{{ result.obitosIdade.level3 }}</span>
        </p>
        <p>
          Total de casos 30-39 anos:
          <span>{{ result.obitosIdade.level4 }}</span>
        </p>
        <p>
          Total de casos 40-49 anos:
          <span>{{ result.obitosIdade.level5 }}</span>
        </p>
        <p>
          Total de casos 50-59 anos:
          <span>{{ result.obitosIdade.level6 }}</span>
        </p>
        <p>
          Total de casos 60-69 anos:
          <span>{{ result.obitosIdade.level7 }}</span>
        </p>
        <p>
          Total de casos 70-79 anos:
          <span>{{ result.obitosIdade.level8 }}</span>
        </p>
        <p>
          Total de casos 80+ anos: <span>{{ result.casosIdade.level9 }}</span>
        </p>
      </div>
    </div>

    <div class="stats__grid" v-else>
      <div class="casos">
        <div>
          <h3>Total de <span>casos</span> por idade até --</h3>
          <p>Total de casos 0-9 anos: <span>--</span></p>
          <p>
            Total de casos 10-19 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 20-29 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 30-39 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 40-49 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 50-59 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 60-69 anos:
            <span>--</span>
          </p>
          <p>
            Total de casos 70-79 anos:
            <span>--</span>
          </p>
          <p>Total de casos 80+ anos: <span>--</span></p>
        </div>
      </div>
      <div class="deaths">
        <h3>Total de <span>mortes</span> por idade até --</h3>
        <p>Total de casos 0-9 anos: <span>--</span></p>
        <p>
          Total de casos 10-19 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 20-29 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 30-39 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 40-49 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 50-59 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 60-69 anos:
          <span>--</span>
        </p>
        <p>
          Total de casos 70-79 anos:
          <span>--</span>
        </p>
        <p>Total de casos 80+ anos: <span>--</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Date",
  data: () => {
    return {
      selectedDates: {
        start: null,
        end: null
      },
      minDate: new Date(2020, 1, 25),
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY"
      },
      selectedDate: "",
      info: null,
      result: {
        casos: null,
        casosIdade: {
          level1: null,
          level2: null,
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          level8: null,
          level9: null
        },
        obitosIdade: {
          level1: null,
          level2: null,
          level3: null,
          level4: null,
          level5: null,
          level6: null,
          level7: null,
          level8: null,
          level9: null
        }
      },
      try: null
    };
  },
  async created() {
    if (this.currentTime()) {
      this.selectedDate = this.getTodaysDate();
      this.$store.commit("SET_DATE", this.getTodaysDate());

      this.currentTime();

      try {
        await this.$store.dispatch("setTotalCasesByDate");
      } catch (error) {
        return error;
      }
      this.info = this.$store.getters.getTotalCasesByDate;

      this.getCasesSumByAges();
      this.getDeathsSumByAges();

      this.result.casos = this.info.totalCasesByDate.confirmados_novos[
        this.diffDays()
      ];
    } else {
      this.selectedDate = this.getYesterdayDate2();
      this.$store.commit("SET_DATE", this.getYesterdayDate2());

      this.currentTime();

      try {
        await this.$store.dispatch("setTotalCasesByDate");
      } catch (error) {
        return error;
      }
      this.info = this.$store.getters.getTotalCasesByDate;

      this.getCasesSumByAges();
      this.getDeathsSumByAges();

      this.result.casos = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_novos[this.diffDays()]
      );
    }
  },
  methods: {
    async getData() {
      if (this.selectedDate != "") {
        this.$store.commit("SET_DATE", this.selectedDate);

        try {
          await this.$store.dispatch("setTotalCasesByDate");
        } catch (error) {
          return error;
        }

        this.info = this.$store.getters.getTotalCasesByDate;

        this.getCasesSumByAges();
        this.getDeathsSumByAges();

        this.result.casos = this.numberWithCommas(
          this.info.totalCasesByDate.confirmados_novos[this.diffDays()]
        );
      } else {
        this.selectedDate = "";

        this.getCasesSumByAges();
        this.getDeathsSumByAges();
      }
    },
    diffDays() {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(2020, 1, 25);
      const secondDate = new Date(this.changeDateOrder());

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      let number = String(diffDays - 1);

      return number;
    },
    changeDateOrder() {
      let dateFormat = this.selectedDate;

      let result = dateFormat.split("-");

      for (let i = 2; i--; ) {
        result[i] = ("0" + result[i]).slice(-2);
      }

      result = result.reverse().join("-");

      return result;
    },
    getTodaysDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();

      this.selectedDate = `${dd}-${mm}-${yyyy}`;

      return this.selectedDate;
    },
    currentTime() {
      let currentTime = new Date().toLocaleTimeString();

      let defaultTime = "20:45:00";

      return currentTime > defaultTime;
    },
    getYesterdayDate() {
      let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
      let dd = String(yesterday.getDate()).padStart(2, "0");
      let mm = String(yesterday.getMonth() + 1).padStart(2, "0");
      let yyyy = yesterday.getFullYear();

      return `${yyyy}-${mm}-${dd}`;
    },
    getYesterdayDate2() {
      let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
      let dd = String(yesterday.getDate()).padStart(2, "0");
      let mm = String(yesterday.getMonth() + 1).padStart(2, "0");
      let yyyy = yesterday.getFullYear();

      return `${dd}-${mm}-${yyyy}`;
    },
    getCasesSumByAges() {
      this.result.casosIdade.level1 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_0_9_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_0_9_m[this.diffDays()]
      );
      this.result.casosIdade.level2 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_10_19_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_10_19_m[this.diffDays()]
      );
      this.result.casosIdade.level3 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_20_29_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_20_29_m[this.diffDays()]
      );
      this.result.casosIdade.level4 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_30_39_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_30_39_m[this.diffDays()]
      );
      this.result.casosIdade.level5 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_40_49_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_40_49_m[this.diffDays()]
      );
      this.result.casosIdade.level6 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_50_59_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_50_59_m[this.diffDays()]
      );
      this.result.casosIdade.level7 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_60_69_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_60_69_m[this.diffDays()]
      );
      this.result.casosIdade.level8 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_70_79_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_70_79_m[this.diffDays()]
      );
      this.result.casosIdade.level9 = this.numberWithCommas(
        this.info.totalCasesByDate.confirmados_80_plus_f[this.diffDays()] +
          this.info.totalCasesByDate.confirmados_80_plus_m[this.diffDays()]
      );
    },
    getDeathsSumByAges() {
      if (this.selectedDate != "") {
        this.result.obitosIdade.level1 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_0_9_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_0_9_m[this.diffDays()]
        );
        this.result.obitosIdade.level2 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_10_19_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_10_19_m[this.diffDays()]
        );
        this.result.obitosIdade.level3 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_20_29_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_20_29_m[this.diffDays()]
        );
        this.result.obitosIdade.level4 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_30_39_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_30_39_m[this.diffDays()]
        );
        this.result.obitosIdade.level5 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_40_49_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_40_49_m[this.diffDays()]
        );
        this.result.obitosIdade.level6 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_50_59_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_50_59_m[this.diffDays()]
        );
        this.result.obitosIdade.level7 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_60_69_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_60_69_m[this.diffDays()]
        );
        this.result.obitosIdade.level8 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_70_79_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_70_79_m[this.diffDays()]
        );
        this.result.obitosIdade.level9 = this.numberWithCommas(
          this.info.totalCasesByDate.obitos_80_plus_f[this.diffDays()] +
            this.info.totalCasesByDate.obitos_80_plus_m[this.diffDays()]
        );
      } else {
        this.result.obitosIdade.level1 = "";
        this.result.obitosIdade.level2 = "";
        this.result.obitosIdade.level3 = "";
        this.result.obitosIdade.level4 = "";
        this.result.obitosIdade.level5 = "";
        this.result.obitosIdade.level6 = "";
        this.result.obitosIdade.level7 = "";
        this.result.obitosIdade.level8 = "";
        this.result.obitosIdade.level9 = "";
      }
    },
    numberWithCommas(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/mixins.scss";

.date {
  width: 100%;
  display: flex;
  justify-items: center;
  flex-direction: column;
  row-gap: 4rem;
  margin: 3.5rem 0;

  @include breakpoint-up(medium) {
    row-gap: 3rem;
  }

  @include breakpoint-up(large) {
    height: 80vh;
    align-items: center;
    justify-content: space-around;
  }

  @include breakpoint-up(xLarge) {
    margin: 8rem 0 16rem;
  }

  .calendar {
    display: grid;
    justify-items: center;
    width: 100%;

    .calendar__stats {
      @include breakpoint-up(medium) {
        align-self: center;
      }

      h1 {
        color: #ffffff;
        font-family: "Montserrat";
        margin: 0;
        font-size: 1.25rem;
        font-weight: 500;
        text-align: center;

        @include breakpoint-up(xLarge) {
          margin-top: 8rem;
        }

        span {
          color: #f1b102;
        }
      }
    }

    h3 {
      color: #ffffff;
      font-family: "Montserrat";
      margin: 1.25rem 0 2rem;
      font-size: 1.0625rem;
      font-weight: 500;
      text-align: center;
    }
  }

  .vc-container {
    --white: #fff;
    --black: #000;
    --gray-100: #f7fafc;
    --gray-200: transparent;
    --gray-300: transparent;
    --gray-400: #4d4d4d;
    --gray-500: #636363;
    --gray-600: #fff;
    --gray-700: #4a5568;
    --gray-800: #ffffff;
    --gray-900: #1a202c;

    padding: 1rem;
    background-color: #262626;
    border: none;
    border-radius: 16px;
    box-shadow: inset 12px 12px 24px #202020, inset -12px -12px 24px #2c2c2c;
    color: #ffffff;

    @include breakpoint-up(large) {
      width: 40vw;
    }

    @include breakpoint-up(xLarge) {
      width: auto;
    }

    .vc-title {
      color: var(--white) !important;
    }

    .vc-weekday {
      color: var(--gray-500);
    }

    .is-disabled {
      color: var(--gray-400);
    }

    .vc-arrow {
      color: var(--white);

      &:focus,
      &:hover {
        border-color: transparent;
      }
    }
  }

  .stats__grid {
    display: grid;
    margin-top: 2rem;

    @include breakpoint-up(medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint-up(xLarge) {
      margin-top: 2rem;
      column-gap: 4rem;
    }

    .casos,
    .deaths {
      border-radius: 10px;
      margin: 1.25rem 2rem;
      padding: 1rem 2.25rem;
      box-shadow: 12px 12px 24px #202020, -12px -12px 24px #2c2c2c;

      @media (min-width: 320px) and (max-width: 480px) {
        margin: 1.25rem 1rem;
      }

      h3 {
        margin: 1.25rem 0 2rem;
        font-family: "Montserrat";
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        color: #888888;
      }

      p {
        font-family: "Raleway";
        color: #ffffff;
        white-space: nowrap;

        span {
          font-style: italic;
        }
      }
    }

    .casos {
      h3 span {
        color: #16a58d;
      }

      p span {
        color: #16a58d;
      }
    }

    .deaths {
      h3 span {
        color: #f77777;
      }

      p span {
        color: #f77777;
      }
    }
  }
}
</style>
