import Vue from "vue";
import Vuex from "vuex";
import cityConfig from "../api/cities.config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cities: [],
    city: [],
    cityParam: null,
    todaysDay: null,
    yesterdayDay: null,
    date: null,
    totalCasesByDate: []
  },
  mutations: {
    SET_CITIES_LIST(state, payload) {
      // payload.cities because payload comes as an object
      // payload = cities [] and status code
      state.cities = payload.cities;
    },
    SET_CITY(state, payload) {
      state.city = payload.info;
      console.log(payload);
    },
    SET_SELECTED_CITY(state, payload) {
      state.cityParam = payload;
    },
    SET_TODAYS_DAY(state, payload) {
      state.todaysDay = payload;
    },
    SET_YESTERDAY_DAY(state, payload) {
      state.yesterdayDay = payload;
    },
    SET_DATE(state, payload) {
      state.date = payload;
    },
    SET_TOTAL_CASES_BY_DATE(state, payload) {
      state.totalCasesByDate = payload;
    }
  },
  actions: {
    async setCitiesList(state) {
      state.commit("SET_CITIES_LIST", await cityConfig.getCitiesList());
    },
    async setCity(state) {
      state.commit(
        "SET_CITY",
        await cityConfig.getCityInfo(
          state.state.yesterdayDay,
          state.state.todaysDay,
          state.state.cityParam
        )
      );
    },
    async setTotalCasesByDate(state) {
      state.commit(
        "SET_TOTAL_CASES_BY_DATE",
        await cityConfig.getInfoByDate(state.state.date)
      );
    }
  },
  getters: {
    getAllCities: state => state.cities,
    getCityInfo: state => state.city,
    getTodaysDate: state => state.todaysDay,
    getYesterdayDate: state => state.yesterdayDay,
    getSelectedDate: state => state.date,
    getTotalCasesByDate: state => state.totalCasesByDate
  }
});
