<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <router-view />

    <footer>
      <div class="grid">
        <div class="socials">
          <a
            href="https://www.linkedin.com/in/nunopereirasous/"
            target="_blank"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="https://www.instagram.com/nunopereirasousa/" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://github.com/NunoPereiraSousa" target="_blank">
            <i class="fab fa-github"></i>
          </a>
          <a href="mailto:nunopereirasousa00@gmail.com">
            <i class="fas fa-envelope"></i>
          </a>
        </div>
        <div class="desc">
          <p>By Nuno Pereira Sousa</p>
          <p>Dados da DGS 2020-2021</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  }
};
</script>

<style lang="scss">
@import "./scss/mixins.scss";

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  background-color: #262626;
  overflow-x: hidden;

  @include breakpoint-up(large) {
    align-items: center;
    height: 100vh;
  }
}

#app {
  width: 100%;
  height: 100%;
}

a,
a:visited,
a:hover,
a:focus,
i,
i:visited,
i:hover,
i:focus {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  outline-offset: 0px;
}

footer {
  margin-top: 2rem;
  padding: 2.25rem 0 1.25rem;
  background: #262626;
  box-shadow: 12px 12px 24px #202020, -12px -12px 24px #2c2c2c;

  // @include breakpoint-up(large) {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  // }

  @include breakpoint-up(medium) {
    padding: 2.25rem 0;
  }

  .grid {
    display: grid;
    justify-items: center;
    align-items: center;

    @include breakpoint-up(medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    .socials {
      i {
        margin: 0 1rem;
        color: #ffffff;
        font-size: 1.45rem;
      }
    }

    .desc {
      p {
        font-family: "Montserrat";
        color: #ffffff;
        text-align: center;

        &:first-child {
          margin-top: 2.25rem;
          color: #f1b102;

          @include breakpoint-up(medium) {
            margin-top: 0;
          }
        }

        &:last-child {
          font-size: 0.875rem;
          font-style: italic;
          color: #4d4d4d;

          @include breakpoint-up(medium) {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
